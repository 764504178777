import React from "react"
import trackTraceHeader from "../../../assets/images/products/netversys/tracktrace_header.svg"
import wmTracking from "../../../assets/images/products/netversys/wm-tracking.svg"
import Sidebar from "./Sidebar"
import { Col, Container, Row } from "react-bootstrap"
import WichtigeMerkmale from "../WichtigeMerkmale"
import LexikonBadgesRandomSection from "../../lexikon/LexikonBadgesRandomSection"
import { StaticImage } from "gatsby-plugin-image"


const UnifiedTrackTrace = () => {

  return (
    <>
      <section className="services-details-area ptb-100">
        <Container className="mt-5">
          <Row>
            <Col lg={8} md={12}>
              <div className="services-details-image">
                <img src={trackTraceHeader} alt="Unified TrackTrace Tracking" />
              </div>

              <div className="services-details-desc">
                <span className="sub-title">NETVERSYS</span>
                <h1 className={"pt-3"}>Sendungsverfolgung mit Unified Track&Trace </h1>
                <p>
                  Die Sendungsverfolgung (Track & Trace) beliebiger Frachtführer ist eine essentielle Funktion von
                  NETVERSYS. Nach Eingabe der Paketnummer identifiziert NETVERSYS den Carrier, startet im Hintergrund
                  eine Abfrage und gibt den Sendungsstatus in der einheitlichen Track & Trace-Recherche-Maske aus.
                  Umständliche Sendungsverfolgung auf den Internetseiten der jeweiligen Frachtführer gehört der
                  Vergangenheit an.
                </p>

                <WichtigeMerkmale items={[
                  "einheitliche Iconsprache",
                  "über Schnittstelle abrufbar",
                  "interne Datenhaltung und On-Demand Carrier-Abfragen",
                  "Bulk-Track&Trace"
                ]} image={<img src={wmTracking}
                               alt="Unified TrackTrace Merkmale"
                               className={"ml-lg-4 ml-0"} />} />

                <h3>Relevantes aus dem Lexikon</h3>

                <LexikonBadgesRandomSection />
              </div>
            </Col>

            <Col lg={4} md={12}>
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="services-area pt-4 pb-70 bg-f1f8fb">
        <Container>
          <span className="mb-2 text-primary"><i className={"bx bxs-bullseye"} /> Im Detail</span>
          <h2>Unified Track&Trace in der Praxis</h2>
          <Row className={"mt-5"}>
            <Col md={6} xs={12}>
              <StaticImage
                src="../../../assets/images/products/netversys/screenshots/nv_tracktrace_framed.png"
                alt="Entgeltmanagement Screenshot"
                placeholder="blurred"
                width={600}
              />
            </Col>
            <Col md={{ offset: 1, span: 5 }} xs={{ offset: 1, span: 11 }}>
              <p>
                Jeder Transportdienstleister verwendet bei der Sendungsverfolgung eigene Nachrichten für bestimmte
                Ereignisse, die mitunter nicht leicht verständlich sind. Wir haben die gängigsten Carrier-Ereignisse
                “entschlüsselt” und diese verschiedenen NETVERSYS Track & Trace Kategorien, wie z.B. “Transport”, “in
                Zustellung” oder “Zugestellt”, zugeordnet. Sowohl textlich als auch mit Symbolen ist nun schnell und
                einheitlich der Status der Sendung einzusehen, Unklarheiten gehören so der Vergangenheit an.
              </p>
              <p>
                Neben der Recherchemaske ist in NETVERSYS eine Track & Trace-Schnittstelle implementiert. Diese
                ermöglicht es anderen Softwarekomponenten, wie ERP-Systemen, Sendungshistorien abzufragen und
                weiterzuverarbeiten um diese beispielsweise in einem Internet-Shop für Kunden zugänglich zu machen.
                Neben der Reduzierung des Supportaufwandes auf Seiten des Shop-Betreibers wird so die Zufriedenheit und
                Bindung des Kunden erhöht.
              </p>
              <p>
                NETVERSYS unterstützt dabei verschiedene Methoden, um die Tracking Daten der Carrier abzugreifen. Zum
                Einen können die Daten On-Demand durch Eingabe einer Sendungsnummer in der Recherchemaske abgerufen
                werden. Zum Anderen kann, periodisch je nach Carrier, ein automatisierter Download von allen
                Sendungsinformatione aller Sendungen des Mandanten erfolgen. Diese Methode wird auch als
                Bulk-Track&Trace bzw. Massenimport von Sendungsverfolgungs-Ereignissen bezeichnet. Diese Vorgehen bietet
                den Vorteil, dass die Sendungsdaten in der Datenbank von Netversys gespeichert und zu jedem Zeitpunkt,
                abgerufen und ausgewertet werden können.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default UnifiedTrackTrace
