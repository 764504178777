import React from "react"
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import Footer from "../../../components/App/Footer"
import UnifiedTrackTrace from '../../../components/ProductDetails/netversys/UnifiedTrackTrace'
import Seo from "../../../components/App/Seo"

const TrackTracePage = () => {
  return (
    <Layout>
      <Seo title={"Unified Track & Trace über alle Carrier."}
           description={"Mit dem einheitlichen Trackingsystem über alle Carrier " +
           "hinweg mit gleicher Symbolsprache, vereinfacht" +
           " es die Sendungsverfolgung auf mehreren Ebenen."}
           image={"/images/og/netversys-main-og.png"}>
        <script type="application/ld+json">{`
              {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [{
                  "@type": "ListItem",
                  "position": 1,
                  "name": "Home",
                  "item": "https://logentis.de"
                },{
                  "@type": "ListItem",
                  "position": 2,
                  "name": "Versandsoftware",
                  "item": "https://logentis.de/versandsoftware/"
                },{
                  "@type": "ListItem",
                  "position": 3,
                  "name": "Unified Track&Trace",
                  "item": "https://logentis.de/versandsoftware/netversys/unified-tracktrace/"
                }]
              }
              `}
        </script>
      </Seo>
      <Navbar />
      <UnifiedTrackTrace />
      <Footer />
    </Layout>
  );
}

export default TrackTracePage
